@import url(https://fonts.googleapis.com/css?family=Merriweather:400,400i,700,700i,900|Montserrat:900);
:root {
  --primary-color: #d23669;
  --primary-color-light: rgb(255, 167, 196);
}

* {
  box-sizing: border-box;
}

html {
  color: rgba(0, 0, 0, 0.9);
  font: 100%/1.75 Morriweather, Georgia, serif;
}

body {
  font-kerning: normal;
  font-family: 'Merriweather','Georgia',serif;
  font-feature-settings: "kern", "liga", "clig", "calt", "kern";
  font-weight: 400;
  margin: 0;
  padding: 0;
  word-wrap: break-word;
  background-color: white;
}

pre,
code {
  font-family: Consolas, Menlo, Monaco, source-code-pro, "Courier New", monospace;
  font-size: 0.85rem;
  line-height: 1.5;
}

pre {
  border-radius: 10px;
  overflow: auto;
  padding: 1em;
}

a {
  color: var(--primary-color);
}
a:hover {
  text-decoration: none;
}

p,
pre,
ul,
ol {
  margin-top: 0;
  margin-bottom: 1.75rem;
  text-align: justify;
}
.Pagination_Pagination__2ILZ- {
  display: block;
  margin: 4.375rem 0 7rem;
}

.Pagination_Pagination__2ILZ- .Pagination_previous__spro9 {
  margin-right: 0.5rem;
}

.Pagination_Pagination__2ILZ- .Pagination_next__3RS0P {
  margin-left: 0.5rem;
}
.BlogIndexPage_title__1v-W_ {
  font-family: Montserrat, sans-serif;
  font-size: 2.5rem;
  font-weight: 900;
  line-height: 3.5rem;
  margin-top: 0;
  margin-bottom: 2.625rem;
  text-rendering: optimizeLegibility;
}

#BlogIndexPage_titleLink__G2-pM {
  color:black;
  text-decoration: none;
}

.BlogIndexPage_articlesList__3hD9E,
.BlogIndexPage_articlesList__3hD9E > li {
  list-style: none;
  padding: 0;
  margin: 0 0 2.625rem;;
}

.BlogIndexPage_footer__3Rmgm {
  text-transform: lowercase;
  margin-top: 7rem;
  margin-bottom: 0.5rem;
}


.LoadingIndicator_LoadingIndicator__2x6KV {
  position: absolute;
  left: 0;
  top: 0;
  height: 3px;
  width: 100%;
  background-color: var(--primary-color-light);
  background-size: 35px 35px;
  z-index: 1000;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.2) inset;
  transition: transform ease-in 300ms, opacity ease-in 300ms;
  transition-delay: 0;
  transform-origin: left center;
  transform: scaleX(0);
  opacity: 0;
}

.LoadingIndicator_LoadingIndicator__2x6KV.LoadingIndicator_active__2H-kk {
  opacity: 1;
  transition-delay: 333ms;
  animation: LoadingIndicator_LoadingIndicator-animation__2fk7c 10s ease-out;
  animation-fill-mode: forwards;
}

@keyframes LoadingIndicator_LoadingIndicator-animation__2fk7c {
  0% {
    transform: scaleX(0);
  }
  10% {
    transform: scaleX(0.3);
  }
  50% {
    transform: scaleX(0.7);
  }
  90% {
    transform: scaleX(0.8);
  }
  100% {
    transform: scaleX(1);
  }
}
.BlogLayout_container__SqY6V {
  margin: 0 auto;
  max-width: 42rem;
  padding: 2.625rem 1.3125rem;
}

.BlogLayout_title__fBxPo {
  font-family: Montserrat, sans-serif;
  font-size: 1.44rem;
  font-weight: 900;
  line-height: 1.1;
  margin-top: 0;
  margin-bottom: 0;
  text-rendering: optimizeLegibility;
}
.BlogLayout_title__fBxPo > a {
  color: var(--primary-color-light);
  text-decoration: none;
}

.BlogPostLayout_title__3c1ug {
  font-family: Montserrat, sans-serif;
  font-weight: 900;
  line-height: 1.1;
  text-rendering: optimizeLegibility;
}
.BlogPostLayout_title__3c1ug > a {
  text-decoration: none;
}
#BlogPostLayout_titlePost__1g8CP {
}
.BlogPostLayout_header__NvLob {
  margin-bottom: 2.4rem;
}
.BlogPostLayout_header__NvLob .BlogPostLayout_title__3c1ug {
  font-size: 2.5rem;
  margin-top: 1.7rem;
  margin-bottom: 0;
}
.BlogPostLayout_header__NvLob .BlogPostLayout_title__3c1ug > a {
  color: black;
}

.BlogPostLayout_footer__18W2p {
  margin-top: 7rem;
}
.BlogPostLayout_footer__18W2p .BlogPostLayout_title__3c1ug {
  font-size: 1.44rem;
  margin-top: 0;
  margin-bottom: 2.4rem;
}
.BlogPostLayout_footer__18W2p .BlogPostLayout_title__3c1ug > a {
  color: var(--primary-color-light);
}

.BlogPostLayout_bio__3Ym2G {
  margin-bottom: 7rem;
}

.BlogPostLayout_links__2zRI8 {
  display: flex;
  justify-content: space-between;
}
.BlogPostLayout_links__2zRI8 .BlogPostLayout_next__3kU3b {
  flex-grow: 1;
  flex-shrink: 1;
  text-align: right;
}

.BlogPostLayout_content__3nsGC hr {
  background: hsla(0,0%,0%,0.2);
  border: none;
  height: 1px;
}

.BlogPostLayout_content__3nsGC h2,
.BlogPostLayout_content__3nsGC h3,
.BlogPostLayout_content__3nsGC h4,
.BlogPostLayout_content__3nsGC h5,
.BlogPostLayout_content__3nsGC h6 {
  font-weight: 900;
  line-height: 1.1;
  margin-top: 3.5rem;
  text-rendering: optimizeLegibility;
}

/**
* Based on copypasta from Remy Bach and Sarah Drasner
*/
.BlogPostLayout_content__3nsGC code,
.BlogPostLayout_content__3nsGC pre {
  color: white;
  font-family: Consolas,Menlo,Monaco,source-code-pro,Courier New,monospace;
  font-feature-settings: normal;
  text-align: left;
  white-space: pre;
  word-spacing: normal;
  word-break: normal;
  word-wrap: normal;
  line-height: 1.5;
  tab-size: 4;
  -webkit-hyphens: none;
          hyphens: none;
}

/* Code blocks */
.BlogPostLayout_content__3nsGC pre {
  overflow: auto;
  padding: 1em;
}

.BlogPostLayout_content__3nsGC :not(pre) > code,
.BlogPostLayout_content__3nsGC pre {
  background: hsla(0,0%,0%,0.9);
}

.BlogPostLayout_content__3nsGC pre::-moz-selection,
.BlogPostLayout_content__3nsGC pre::selection {
  background: hsl(207, 4%, 16%);
}

/* Text Selection colour */

.BlogPostLayout_content__3nsGC pre::selection,
.BlogPostLayout_content__3nsGC pre ::selection {
  text-shadow: none;
  background: hsla(0, 0%, 100%, 0.15);
}

/* Inline code */
.BlogPostLayout_content__3nsGC :not(pre) > code {
  border-radius: .3em;
  background: rgba(255,229,100,0.2);
  color: #1a1a1a;
  padding: .15em .2em .05em;
  white-space: normal;
}
.BlogPostLayout_content__3nsGC a > code {
  color: var(--primary-color);
}

.BlogPostLayout_content__3nsGC .highlighted-line {
  background-color: hsla(207, 95%, 15%, 1);
  display: block;
  margin-right: -1em;
  margin-left: -1em;
  padding-right: 1em;
  padding-left: 0.75em;
  border-left: 0.25em solid #ffa7c4;
}

.BlogPostLayout_content__3nsGC .token.attr-name {
  color: rgb(173, 219, 103);
  font-style: italic;
}

.BlogPostLayout_content__3nsGC .token.comment {
  color: rgb(99, 119, 119);
}

.BlogPostLayout_content__3nsGC .token.string,
.BlogPostLayout_content__3nsGC .token.url {
  color: rgb(173, 219, 103);
}

.BlogPostLayout_content__3nsGC .token.variable {
  color: rgb(214, 222, 235);
}

.BlogPostLayout_content__3nsGC .token.number {
  color: rgb(247, 140, 108);
}

.BlogPostLayout_content__3nsGC .token.builtin,
.BlogPostLayout_content__3nsGC .token.char,
.BlogPostLayout_content__3nsGC .token.constant,
.BlogPostLayout_content__3nsGC .token.function {
  color: rgb(130, 170, 255);
}

.BlogPostLayout_content__3nsGC .token.punctuation {
  color: rgb(199, 146, 234);
}

.BlogPostLayout_content__3nsGC .token.selector,
.BlogPostLayout_content__3nsGC .token.doctype {
  color: rgb(199, 146, 234);
  font-style: 'italic';
}

.BlogPostLayout_content__3nsGC .token.class-name {
  color: rgb(255, 203, 139);
}

.BlogPostLayout_content__3nsGC .token.tag,
.BlogPostLayout_content__3nsGC .token.operator,
.BlogPostLayout_content__3nsGC .token.keyword {
  color: #ffa7c4;
}

.BlogPostLayout_content__3nsGC .token.boolean {
  color: rgb(255, 88, 116);
}

.BlogPostLayout_content__3nsGC .token.property {
  color: rgb(128, 203, 196);
}

.BlogPostLayout_content__3nsGC .token.namespace {
  color: rgb(178, 204, 214);
}

/* TODO: move to BlogPostLayout.module.css*/
.BlogPostLayout_bash__DQ3hI code:before {
  content: "$";
  margin-right: 5px;
} 

.Toggler_Toggler__3y-rG {
  position: relative;
  float: right;
  width: 60px;
  height: 34px;
}

.Toggler_Toggler__3y-rG input {
  opacity: 0;
  width: 0;
  height: 0;
}

.Toggler_Slider__7tBxm {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  /* -webkit-transition: .4s; */
  /* transition: .4s; */
}

.Toggler_Slider__7tBxm:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  /* -webkit-transition: .4s; */
  /* transition: .4s; */
}

input:checked + .Toggler_Slider__7tBxm {
  background-color: #f3de21;
}

input:focus + .Toggler_Slider__7tBxm {
  box-shadow: 0 0 1px #f3de21;
}

input:checked + .Toggler_Slider__7tBxm:before {
  /* -webkit-transform: translateX(26px); */
  /* -ms-transform: translateX(26px); */
  transform: translateX(26px);
}
.Toggler_Slider__7tBxm {
  border-radius: 34px;
}

.Toggler_Slider__7tBxm:before {
  border-radius: 50%;
}

.ArticleMeta_tags__3QOqP,
.ArticleMeta_tags__3QOqP li {
  display: inline;
  list-style: none;
  padding: 0;
  margin: 0;
}

.ArticleMeta_tags__3QOqP li:not(:first-child)::before {
  content: ', ';
}
