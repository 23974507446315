.title {
  font-family: Montserrat, sans-serif;
  font-size: 2.5rem;
  font-weight: 900;
  line-height: 3.5rem;
  margin-top: 0;
  margin-bottom: 2.625rem;
  text-rendering: optimizeLegibility;
}

#titleLink {
  color:black;
  text-decoration: none;
}

.articlesList,
.articlesList > li {
  list-style: none;
  padding: 0;
  margin: 0 0 2.625rem;;
}

.footer {
  text-transform: lowercase;
  margin-top: 7rem;
  margin-bottom: 0.5rem;
}
