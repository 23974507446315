.Toggler {
  position: relative;
  float: right;
  width: 60px;
  height: 34px;
}

.Toggler input {
  opacity: 0;
  width: 0;
  height: 0;
}

.Slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  /* -webkit-transition: .4s; */
  /* transition: .4s; */
}

.Slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  /* -webkit-transition: .4s; */
  /* transition: .4s; */
}

input:checked + .Slider {
  background-color: #f3de21;
}

input:focus + .Slider {
  box-shadow: 0 0 1px #f3de21;
}

input:checked + .Slider:before {
  /* -webkit-transform: translateX(26px); */
  /* -ms-transform: translateX(26px); */
  transform: translateX(26px);
}
.Slider {
  border-radius: 34px;
}

.Slider:before {
  border-radius: 50%;
}
